<template>
  <div class="content is-flex is-justify-content-center is-align-items-center">
    <div class="p-2 box">
      <p class="title">Login</p>
      <form @submit.prevent="onSubmit">
        <div class="field mb-1">
          <input
            v-model="user.email"
            class="input"
            type="text"
            placeholder="Email"
            autocomplete="username"
          />
        </div>

        <div class="field">
          <input
            v-model="user.password"
            class="input"
            type="password"
            placeholder="Senha"
            autocomplete="current-password"
          />
        </div>
        <hr />

        <div class="level">
          <button
            :disabled="!user.email || !user.password"
            class="button is-info"
            type="submit"
          >
            Login
          </button>
          <router-link to="/recover_password" class="is-size-7 is-pulled-right"
            >Esqueci minha senha!</router-link
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user: {},
    };
  },
  methods: {
    onSubmit() {
      this.$http
        .post(`${process.env.VUE_APP_URI}client/token`, this.user)
        .then((response) => {
          this.$store.commit("setToken", response.data);
          this.$router.push("/terminals");
        })
        .catch(() => {
          this.$store.commit("setError", true);
          setTimeout(() => this.$store.commit("setError"), 800);
        });
    },
  },
  created() {
    this.$store.commit("setToken");
  },
};
</script>
<style scoped>
.content {
  height: 100% !important;
}
.box {
  width: 33% !important;
  border-radius: 0 !important;
}
</style>